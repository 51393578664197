/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
export { wrapRootElement } from "./src/apollo/wrap-root-element"

export const onClientEntry = async () => {
  // Add IntersectionObserver polyfill
  if (typeof IntersectionObserver === "undefined") {
    await import("intersection-observer")
  }
}
