import React from "react"
import fetch from "isomorphic-fetch" //eslint-disable-line
import { ApolloProvider } from "react-apollo"
import { ApolloProvider as ApolloHooksProvider } from "react-apollo-hooks"
import { client } from "./client"

export const wrapRootElement = ({ element }) => (
  <ApolloProvider client={client}>
    <ApolloHooksProvider client={client}>{element}</ApolloHooksProvider>
  </ApolloProvider>
)
