import { ApolloClient } from "apollo-client"
import { HttpLink } from "apollo-link-http"
import { InMemoryCache } from "apollo-cache-inmemory"

export const client = new ApolloClient({
  link: new HttpLink({
    uri: process.env.WORDPRESS_GRAPHQL_URL,
  }),
  cache: new InMemoryCache(),
  // for SSR, use:
  // cache: new Cache().restore(window.__APOLLO_STATE__ || {})
})
